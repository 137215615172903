<template lang="pug">
input.w-full(type="tel", v-model="expiryDate", placeholder="MM/YY")
input.input-date(data-paycomet="dateMonth", v-model="ccMonth", type="hidden")
input.input-date.ml-1(
  data-paycomet="dateYear",
  v-model="ccYear",
  type="hidden"
)
</template>

<script>
export default {
  name: "paycomet.input-date-expiry",

  data: () => ({
    expiryDate: "",
    ccMonth: null,
    ccYear: null,
  }),
  watch: {
    expiryDate(n, o) {
      const expiryNumbers = +n.replace("/", "");
      const newExpirySplit = n.split("/");
      const oldExpirySplit = o.split("/");
      const newMonthString = newExpirySplit[0];
      const newYearString = newExpirySplit[1];
      const oldMonthString = oldExpirySplit[0];
      const oldYearString = oldExpirySplit[1];
      let value = newMonthString;

      console.log({
        expiryNumbers,
        newExpirySplit,
        newMonthString,
        newYearString,
        oldYearString,
      });

      if (Number.isNaN(expiryNumbers)) {
        this.expiryDate = o;
        return;
      }
      console.log("isNan");

      if (newMonthString[0] > 1) {
        this.expiryDate = "0" + newMonthString[0];
        return;
      }
      console.log("newMonthString[0] > 1");

      if (
        newMonthString > 12 ||
        newMonthString == "00" ||
        newMonthString.length > 2
      ) {
        this.expiryDate = o;
        return;
      } else if (newMonthString.length == 2) {
        value += "/";
      }
      console.log("verification month");

      if (
        !newYearString &&
        oldYearString != undefined &&
        (oldYearString == "/" || oldYearString == "")
      ) {
        this.expiryDate = newMonthString[0];
        return;
      }

      console.log("!newYearString && oldYearString");

      if (newYearString != undefined && newYearString[0] < 2) {
        this.expiryDate = value;
        return;
      } else if (
        newYearString != undefined &&
        newYearString[1] != undefined &&
        newYearString[1] == "1"
      ) {
        this.expiryDate = value + newYearString[0];
        return;
      } else if (newYearString != undefined && newYearString.length > 2) {
        value += oldYearString;
      } else if (newYearString != undefined) {
        value += newYearString;
      }

      this.expiryDate = value;
      console.log({ newMonthString, newYearString });
      this.ccMonth = newMonthString;
      this.ccYear = newYearString;
    },
  },
};
</script>

<style lang="scss" scoped></style>
