<template lang="pug">
BaseCard
  XyzTransition(xyz="fade")
    .loading(v-if="isLoading")
  .title.text-gray-500.font-bold.text-lg Verifica el correo electronico para crear la cuenta
  .form.pt-4
    BaseInputText(
      label="Correo electronico:",
      v-model="form.account_email",
      :error="errorMail"
    )
    FormsBaseInputCheckbox.mt-6(
      v-model="form.checkbox",
      :errorInput="errorCheckbox"
    ) He leído y acepto los <span></span>
      router-link(:to="{ name: 'Terminos' }") Términos y Condiciones
      |
      | y la <span></span>
      router-link(:to="{ name: 'Privacidad' }") Política de privacidad.
    .flex.justify-center.mt-12
      .action-button(
        @click="createAccount",
        :class="{ disabled: isLoading || !isFormValid }"
      ) Crear cuenta y Descargar
</template>

<script>
import { UsersRepository } from "@/repository/users.repository";

const timer = (seconds) =>
  new Promise((resolve) => setTimeout(() => resolve(), seconds));

export default {
  name: "create-account-component",
  data: () => ({
    form: {
      account_email: "",
      checkbox: false,
    },
    errorMail: "",
    errorCheckbox: "",
    isLoading: false,
    regEmail:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
  }),
  created() {
    this.form.account_email =
      this.$store.state.DataCvBuilderStore.personal_data.profile_email;
  },
  methods: {
    async createAccount() {
      if (this.isLoading) return;
      this.errorMail = "";
      this.isLoading = true;
      if (!this.form.checkbox) {
        this.errorCheckbox = true;
      }
      if (this.form.account_email == "") {
        this.errorMail = "El correo electronico no es valido";
      }
      if (this.isFormValid) {
        await timer(500);
        await UsersRepository.createAccount({
          user_email: this.form.account_email,
          user_lang: this.$store.getters["UserStore/getLanguange"],
          pdf_base64: this.$store.getters["DataCvViewerStore/cvParsed"],
          snapshot_base64: this.$store.getters["DataCvViewerStore/cvSnapshot"],
          data_cv: await this.$store.dispatch(
            "DataCvBuilderStore/getFullCVJson"
          ),
        })
          .then(({ access_token, curriculumEntity }) => {
            this.$store.commit("WebOptionsStore/showModal");
            this.$store.dispatch("UserStore/autoLogin", { access_token });
          })
          .catch(({ response }) => {
            if (response.data.message == "user-exists") {
              this.errorMail = "Esta cuenta ya existe.";
            } else {
              this.errorMail = "Ha ocurrido un error inesperado.";
            }
          });
      }
      this.isLoading = false;
    },
    async createFormPayment() { },
  },
  computed: {
    getErrorEmail() {
      return this.errorMail;
    },
    isEmailValid() {
      return this.regEmail.test(this.form.account_email);
    },
    isFormValid() {
      return this.isEmailValid && this.form.checkbox;
    },
  },
  watch: {
    isEmailValid: {
      handler(n) {
        console.log(n);
        if (n) this.errorMail = "";
        else this.errorMail = "El correo electronico no es valido";
      },
    },
    "form.checkbox": {
      deep: true,
      handler(n) {
        this.errorCheckbox = false;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.box-cv {
  @apply flex w-full bg-blue-100 rounded-lg overflow-hidden border border-blue-200;
}
.back-cv {
  @apply flex justify-center w-full mt-12 -mb-2;
}

.loading {
  @apply absolute top-0 left-0 right-0 bottom-0 bg-white z-10 opacity-30;
}

.action-button {
  @apply text-lg px-12 py-4 text-center bg-blue-500 text-white rounded-lg font-medium cursor-pointer inline-block transition-opacity;

  &.disabled {
    @apply cursor-default opacity-70;
  }
}
</style>
