<template lang="pug">
.flex.flex-col.w-full
  .w-full.max-w-5xl.mx-auto
    .container
      .section-header
        XyzTransition(xyz="fade up-1", appear)
          .header Descarga ahora tu currículum profesional
        XyzTransition(xyz="fade up-1 delay-1", appear)
          .subtitle Descarga tu cv en formato PDF profesional.
      .section-cv
        XyzTransition(xyz="fade delay-1 up-1", appear)
          BaseCard
            .first-card
              .col-1
                XyzTransition(xyz="fade delay-3 left-1", appear)
                  .block
                    .first-card-title Ventajas:
                    ul
                      li(v-for="(itemText, index) in listText", :key="index") 
                        .item
                          BaseCustomIcon.icon(
                            nameIcon="mdiCheckCircle",
                            :size="24"
                          )
                          .text {{ itemText }}
              .box-cv
                .back-cv
                  XyzTransition(xyz="fade delay-1 down-4", appear)
                    .box-img
                      img(:src="getImage", alt="alt")
        CreateAccountComponent(v-if="!isLoggedUser") 
        AccountCreateComponent(
          v-if="isLoggedUser && $store.getters['WebOptionsStore/showModalNewAccount']"
        )
        FormPaymentComponent(v-if="isLoggedUser" :trialPrice="trialPrice")
        .flex.justify-center.mt-0
          .button-back(@click="back")
            BaseCustomIcon(nameIcon="mdiChevronLeftCircle", :size="32")
            .text.pl-6 Volver atras
      .section-terms
        .text Después del pago, puede descargar su CV inmediatamente y renuncia al derecho de desistimiento. Obtendrá acceso completo a su cuenta durante 24 horas para editar su CV y utilizar todas las demás funcionalidades. Después del período de prueba, su cuenta se renovará automáticamente y mensualmente por {{ SubscriptionPrice }}, y podrás continuar usando todas las funcionalidades. Podrás cancelar tu cuenta en cualquier momento.
  .mt-16
    footerComponent
</template>

<script>
import footerComponent from "@/components/structure/navFooter/navFooter.vue";
import CreateAccountComponent from "@/components/www/builder/payment/create-account-component.vue";
import FormPaymentComponent from "@/components/www/builder/payment/form-payment-component.vue";
import AccountCreateComponent from "@/components/www/builder/payment/account-created-component.vue";

import ParseCash from "@/composables/parse-cash";
const { parseCash } = ParseCash();

export default {
  name: "step-3-payment",
  components: {
    footerComponent,
    CreateAccountComponent,
    AccountCreateComponent,
    FormPaymentComponent,
  },
  data: () => ({
    listText: [
      "Descarga de forma ilimitada tu CV desde cualquier lugar.",
      "Crea y modifica tu CV siempre que lo necesites.",
      "Utiliza plantillas completamente personalizables y únicas.",
      "Comparte con un click tu CV.",
      "Sín compromiso, cancela la cuenta cuando quieras.",
    ],
  }),
  computed: {
    isLoggedUser() {
      return this.$store.getters["UserStore/getIsLogged"];
    },
    getImage() {
      return (
        this.$store.getters["DataCvViewerStore/cvSnapshot"] ||
        localStorage.getItem("cvSnapshotBase64")
      );
    },
    defaultProduct() {
      return this.$store.getters["ProductsStore/getproductDefault"];
    },
    trialPrice() {
      return parseCash(
        this.defaultProduct.price_trial,
        this.defaultProduct.product_currency,
        this.defaultProduct.product_sign
      );
    },
    SubscriptionPrice() {
      return parseCash(
        this.defaultProduct.price_subscription,
        this.defaultProduct.product_currency,
        this.defaultProduct.product_sign
      );
    },
  },
  methods: {
    back() {
      const cv_id = this.$store.getters["DataCvBuilderStore/getCurriculumId"];
      if (cv_id) {
        return this.$router.push({
          name: "builder/creator/with-id",
          params: { curriculum_id: cv_id },
        });
      } else {
        return this.$router.push({ name: "builder/creator" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.section-header {
  @apply flex justify-center flex-col text-center items-center mt-12 mb-12 xs:mt-20;
  .header {
    @apply mb-6 text-2xl font-black text-gray-600 text-center xs:text-4xl;
  }
  .subtitle {
    @apply text-base text-blue-400 xs:text-lg;
  }
}

.first-card {
  @apply grid grid-cols-1 gap-8 md:grid-cols-2;

  .first-card-title {
    @apply font-bold text-xl text-blue-400 xs:text-2xl;
  }

  .col-1 {
    @apply col-span-1 flex items-center mx-auto md:mx-0;

    ul {
      @apply py-6 md:py-8;
      li {
        .item {
          @apply flex items-center text-green-500;
          .text {
            @apply pl-2 text-sm text-gray-500 font-medium;
          }
          .icon {
            @apply flex-shrink-0;
          }
        }

        & + li {
          @apply mt-4;
        }
      }
    }

    .action-button {
      @apply px-4 py-3 text-center bg-green-500 text-white rounded-lg font-medium cursor-pointer;
    }
  }

  .box-cv {
    @apply flex w-full bg-blue-50 rounded-lg overflow-hidden border;

    .back-cv {
      @apply flex justify-center w-full mt-6 -mb-2 items-end;

      .box-img {
        width: 100%;
        height: 100%;
        max-width: 390px;
        @apply shadow-lg rounded-md overflow-hidden mx-4 max-h-60 md:max-h-80;
      }
    }
  }
}
.section-cv {
  @apply w-full flex flex-col gap-16;
}

.button-back {
  @apply flex items-center text-lg pr-12 pl-6 py-4 text-center bg-gray-900 text-white rounded-lg font-medium cursor-pointer;
}

.section-terms {
  @apply max-w-2xl mx-auto mt-20 text-gray-400 text-center leading-6 text-xs md:text-sm;
}
</style>
