<template lang="pug">
.payments-wrapper
  form#paycometPaymentForm(
    role="form",
    action="javascript:document.dispatchEvent(new CustomEvent('build', {bubbles: true}))",
    method="POST",
    ref="myForm"
  )
    input(
      type="hidden",
      data-paycomet="jetID",
      value="DqJenu0fcPoXEONYa9Fxdbj54IR7UT13"
    )
    input(type="hidden", data-paycomet="no-mask", value="")
    input(
      type="hidden",
      name="username",
      data-paycomet="cardHolderName",
      placeholder="",
      ref="cardholder",
      value="disabled",
      disabled
    )

    .flex.flex-col.mb-6
      FormsBaseLabel(text="Número Tarjeta", :class="{ label_error: panError }")
      .input-text(:class="{ input_error: panError }")
        #paycomet-pan
        input(
          :paycomet-style="inputStyle",
          paycomet-name="pan",
          paycomet-placeholder="1234 1234 1234 1234"
        )
    .grid.grid-cols-2.gap-6
      .form-group.flex.flex-col
        FormsBaseLabel(
          text="Fecha expiración",
          :class="{ label_error: dateError }"
        )
        .input-text(:class="{ input_error: dateError }")
          PaycometInputDateExpiryComponent
      .form-group.flex.flex-col
        FormsBaseLabel(text="CVV")
        .input-text
          #paycomet-cvc2
          input(
            :paycomet-style="inputStyle",
            paycomet-name="cvc2",
            type="text",
            paycomet-placeholder="CVV"
          )
    .flex.justify-center.mt-12.flex-col.items-center
      .flex.flex-shrink-0.flex-grow-0.justify-center
        button.action-button(type="submit", @click="clickPay") Pagar y descargar
      #paymentErrorMsg.hidden(ref="paymentErrorMSG")
      .error(v-if="defaultError") {{ defaultError }}
</template>


<script>
import { UsersRepository } from "@/repository/users.repository";
import PaycometInputDateExpiryComponent from "./paycomet.input-date-expiry.vue";
import DownloadFile from "@/composables/download-file";
export default {
  name: "paycomet",
  emits: ["paymentSucceded", "isLoading", "error"],
  components: {
    PaycometInputDateExpiryComponent,
  },
  props: {
    forceError: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    ccMonth: null,
    ccYear: null,
    dateError: false,
    panError: false,
    defaultError: null,
    inputStyle:
      "font-family: 'Roboto', sans-serif; sans-serif; width: 100%; height: 24px; font-size:16px; padding: 0rem; border:0px; outline: none; background-color: transparent;",
  }),
  watch: {
    dateError(n) {
      if (n) {
        setTimeout(() => {
          this.dateError = false;
        }, 3500);
      }
    },
    panError(n) {
      if (n) {
        setTimeout(() => {
          this.panError = false;
        }, 3500);
      }
    },
    defaultError(n) {
      if (n) {
        setTimeout(() => {
          // this.defaultError = false;
        }, 3500);
      }
    },
  },
  mounted() {
    const existScript = document.getElementById("paycomet-script");
    if (!existScript) {
      this.addScript();
      document.addEventListener("build", this.onSubmit, false);
    }
    if (this.forceError) this.setInputErrors("");
  },
  created() {
    this.$nextTick(() => {
      this.$emit("isLoading", false);
    });
  },
  beforeUnmount() {
    this.removeScript();
  },
  methods: {
    addScript() {
      const externalScript = document.createElement("script");
      externalScript.setAttribute(
        "src",
        "https://api.paycomet.com/gateway/paycomet.jetiframe.js"
      );
      externalScript.setAttribute("id", "paycomet-script");
      document.head.appendChild(externalScript);
    },
    removeScript() {
      document.removeEventListener("build", this.onSubmit, false);
      const existScript = document.getElementById("paycomet-script");
      existScript.parentElement.removeChild(existScript);
    },
    async onSubmit() {
      const formData = new FormData(this.$refs.myForm);
      var object = {};
      formData.forEach(function (value, key) {
        object[key] = value;
      });
      await this.executePayment(object.paytpvToken);
    },
    async clickPay() {
      this.$emit("isLoading", true);
      this.defaultError = null;
      setTimeout(() => {
        if (this.$refs.paymentErrorMSG.innerHTML != "") {
          this.setInputErrors(this.$refs.paymentErrorMSG.innerHTML);
        }
      }, 500);
    },
    setInputErrors(error) {
      switch (error.trim()) {
        case "El formato del año no es correcto":
        case "El formato del mes no es correcto": {
          this.dateError = true;
          break;
        }
        case "El número de tarjeta no es correcto": {
          this.panError = true;
          break;
        }
        case "error-payment": {
          this.$emit("isLoading", false);
          break;
        }
        default: {
          this.defaultError = "Error en los datos introducidos.";
        }
      }
      this.$emit("isLoading", false);
      // this.$emit("error");
    },
    async executePayment(token) {
      const product = this.$store.getters["ProductsStore/getproductDefault"];
      await UsersRepository.payProduct(product._id, token)
        .then(async (data) => {
          if (data.isError) this.$emit("error");
          await this.verificationResultExecutePayment(data);
        })
        .catch((e) => {
          this.$emit("error");
        });
      this.$emit("isLoading", false);
    },
    async verificationResultExecutePayment(result) {
      console.log(result);
      if (result.requires_action && result.challenge_url) {
        window.location.replace(result.challenge_url);
      } else if (
        result.requires_action &&
        !result.challenge_url &&
        result.payment_id
      ) {
        UsersRepository.verificationPayment(result.payment_id)
          .then((result) => {
            if (result.payment_status == "succeeded") {
              this.$emit("paymentSucceded");
            } else {
              this.$emit("error");
            }
          })
          .catch(({ response }) => {
            this.$emit("error");
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.payments-wrapper {
  @apply mt-4;
}

.input-text {
  height: 48px;
  @apply overflow-hidden bg-gray-100 py-3 px-4 rounded-lg transition-colors border border-gray-200;
}

.input-date {
  max-width: 24px;
  @apply flex-grow-0 flex-shrink w-full bg-transparent;
}
.error-text {
  @apply text-sm pl-1 pt-1 text-red-400;
}

input {
  @apply w-full;
}

.action-button {
  @apply text-xl px-12 py-4 text-center bg-blue-500 text-white rounded-lg font-medium cursor-pointer inline-block transition-opacity;
  &.disabled {
    @apply cursor-default opacity-70;
  }
}

.label_error {
  @apply text-red-500;
}

.input_error {
  @apply border-red-200;
}

.error {
  @apply mt-4 text-sm text-red-500;
}
</style>
